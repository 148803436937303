import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        brandName: '',//商标名称
        imgUrl: '',//商标图片URL
        typeface: '', //字体类型
        colorType: '',//商标颜色类型1.黑白2.原色
        peerData: [],
        authCompany: [],//认证企业
		UserId: '822971807461105920',
		CorpId: '822971807461105921',
		isLogin: false,
		applicant: ''
    },	
    mutations: {
		saveLogin(state, val) {
		    state.isLogin = true;
		    state.applicant = val;
		},
        saveData1(state, val1) {
            state.brandName = val1;
        },
        saveData2(state, val2) {
            state.imgUrl = val2;
        },
        saveData3(state, val3) {
            state.typeface = val3;
        },
        saveData4(state, val4) {
            state.colorType = val4;
        },
        saveData5(state, val5) {
            state.peerData = val5;
        },
        SET_AUTH_COMPANY(state, val) {
            state.authCompany = val
        },
    },
	getters: {
		CorpId: state => state.CorpId,
		UserId: state => state.UserId,	
		isLogin: state => state.isLogin,	
		applicant: state => state.applicant,	
	},
})

export default store;
