<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  mounted () {
    
  },
  watch: {
    '$route' () {
      if (window._czc) {		  
        let location = window.location
        let contentUrl = location.pathname + location.hash
        let refererUrl = '/'
        window._czc.push(['_trackPageview', contentUrl, refererUrl])
      }
    }
  }
}

</script>

<style>
  *{
    margin: 0;
    padding: 0;
  }
  img{
    border:none;
  }
  ul {
    padding: 0;
    list-style: none;
  }
  #app{
    background-color: #FAFAFA;
  }
</style>
