import Axios from "axios";
import {Message} from "element-ui";
import global_ from '../config/global'
const service = Axios.create({
  timeout:20000
});

import {getCookie} from "../cookie";
//请求前
service.interceptors.request.use(
  config => {
    if(config.url.indexOf(global_.new_url) !== -1){
      let TIME = Date.parse(new Date()) || 0;//请求发起时间戳
      let TEST_MODE = false;//是否开始测试数据
      config.headers["X-USER-AGENT"] = `BJZC|1.5.0|PC|PC|1.5|${TIME}||${TEST_MODE}||`
      config.headers["AuthToken"] = getCookie('token') || ''
      config.headers["X-Request-Origin"] = 'XCX'
    }
    return config
  },
  error => Promise.reject(error)
)

//请求返回
service.interceptors.response.use(
  res => {
    if (res.data.code !== 200&&res.data.code !== 1&&res.data.code !== "1"&&res.data.code !== "0"&&res.data.code !== 110&&res.data.code !== undefined) {
      Message({
        type: 'error',
        message: res.data.msg || '服务器繁忙，请稍后重试',
        duration: 2 * 1000
      })
      console.warn(res.data.msg)
    }
    return res
  },
  error => {

    console.warn(error)
    Message({
      message: error.message,
      type: 'error',
      duration: 2 * 1000
    })

    return error
  }
)

export default service
